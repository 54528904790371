import React, { useState, useRef, useEffect } from "react";
import Seo from "@components/common/Seo";
import Layout from "@components/layouts/UserLayout";
import { Link } from "gatsby";
import { SiMaildotru, SiOnlyfans, SiSuperuser } from "react-icons/si";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import useAuth from "@components/hooks/useAuth";
import { navigate } from "gatsby";
import InputObject from "@components/common/InputObject";

const passwordRegexPattern = /^.{6,}$/;
const regex = new RegExp(passwordRegexPattern);

const Component = ({ location }) => {
    const { register, loading } = useAuth();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [repeatEmail, setRepeatEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [formError, setFormError] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const usernameRef = useRef(null);
    const emailRef = useRef(null);
    const repeatEmailRef = useRef(null);
    const passwordRef = useRef(null);
    const repeatPasswordRef = useRef(null);

    useEffect(() => {
        if (process.env.GATSBY_AUTH_ENABLED !== "true") {
            navigate("/");
        }
    }, []);

    const onSubmit = async e => {
        e && e.preventDefault();

        if (loading) {
            return;
        }

        if (email !== repeatEmail) {
            setFormError("Los emails no coinciden.");
            repeatEmailRef.current.focus();
            console.log(repeatEmailRef);
            return;
        }

        if (password !== repeatPassword) {
            setFormError("Las contraseñas no coinciden.");
            repeatPasswordRef.current.focus();
            return;
        }

        if (!regex.test(password)) {
            setFormError(
                "La contraseña no cumple los requisitos. Recuerda que ha de tener un mínimo de 6 caracteres.",
            );
            return;
        }

        const success = await register({
            username,
            email,
            password,
        });

        if (success) {
            toast.success("Sesión iniciada con éxito. En breve será redirigido.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 2000,
            });

            // Reseteamos datos
            setEmail("");
            setRepeatEmail("");
            setUsername("");
            setPassword("");
            setRepeatPassword("");
            setAcceptTerms(false);
            setFormError(null);

            // Redireccionamos si es el caso
            let redirect = "";

            try {
                const params = new URLSearchParams(location.search);
                redirect = params.get("redirect");
            } catch (err) {
                console.log(err?.message);
            }

            // Redireccionamos en caso que haya alguna url en concreto
            setTimeout(() => {
                navigate(redirect && redirect !== "" ? redirect : "/private");
            }, 2000);
        } else {
            setFormError(
                "No se ha podido crear el usuario de forma correcta. Ten en cuenta que este correo puede estar ya en uso por otro usuario. Revisa los datos y vuelve a intentarlo.",
            );
        }
    };

    return (
        <>
            <Seo title="Crear cuenta de usuario" />
            <Layout image={"bgs/bgAcademy5"}>
                <div className="flex gap-5 justify-between">
                    <div className="-mt-6 text-gray-500 text-xs">
                        ¿Tienes ya una cuenta?{" "}
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/login"
                        >
                            Inicia sesión ahora
                        </Link>
                    </div>

                    <div className="-mt-6 text-gray-500 text-xs text-right">
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/recuperar-cuenta"
                        >
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </div>
                </div>

                <div className="flex h-full">
                    <div className="my-auto">
                        <h1 className={styles.title}>Crear cuenta de usuario</h1>
                        <h2 className={styles.subtitle}>
                            Anim irure amet eiusmod occaecat excepteur ipsum ut Lorem. Duis sit quis
                            est cupidatat.
                        </h2>

                        <form className={styles.form} onSubmit={onSubmit}>
                            <InputObject
                                title="Identificador"
                                reference={usernameRef}
                                type="text"
                                id="text"
                                name="text"
                                required={true}
                                placeholder="Identificador del usuario"
                                value={username}
                                setValue={setUsername}
                                icon={SiSuperuser}
                                tabIndex="0"
                            />

                            <InputObject
                                title="Correo electrónico"
                                reference={emailRef}
                                type="email"
                                id="email"
                                name="email"
                                required={true}
                                placeholder="Dirección de correo electrónico"
                                value={email}
                                setValue={setEmail}
                                icon={SiMaildotru}
                                tabIndex="0"
                            />

                            <InputObject
                                title="Correo electrónico (Repetición)"
                                reference={repeatEmailRef}
                                type="email"
                                id="repeatEmail"
                                name="repeatEmail"
                                required={true}
                                placeholder="Dirección de correo electrónico (repetición)"
                                value={repeatEmail}
                                setValue={setRepeatEmail}
                                icon={SiMaildotru}
                                tabIndex="0"
                            />

                            <InputObject
                                title="Contraseña"
                                reference={passwordRef}
                                type="password"
                                id="password"
                                name="password"
                                required={true}
                                placeholder="Contraseña"
                                value={password}
                                setValue={setPassword}
                                icon={SiOnlyfans}
                                tabIndex="0"
                                showStrengthBar={true}
                            />

                            <InputObject
                                title="Contraseña (Repetición)"
                                reference={repeatPasswordRef}
                                type="password"
                                id="repeatPassword"
                                name="repeatPassword"
                                required={true}
                                placeholder="Contraseña (repetición)"
                                value={repeatPassword}
                                setValue={setRepeatPassword}
                                icon={SiOnlyfans}
                                tabIndex="0"
                            />

                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="acceptTerms"
                                    name="acceptTerms"
                                    required={true}
                                    value={acceptTerms}
                                    onChange={() => setAcceptTerms(!acceptTerms)}
                                />

                                <label htmlFor="acceptTerms" className="text-xs text-gray-600">
                                    Acepto los{" "}
                                    <Link
                                        to="/privacidad/politica-de-privacidad"
                                        className="text-ccyan"
                                    >
                                        Términos y Condiciones
                                    </Link>
                                    .
                                </label>
                            </div>

                            <div>
                                <button className={styles.actionButton(loading)} disabled={loading}>
                                    {loading && <AiOutlineLoading className={styles.loadingIcon} />}
                                    <span>Crear usuario</span>
                                </button>

                                <div className={styles.formError(formError)}>{formError || ""}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    );
};

const styles = {
    title: "text-3xl lg:text-4xl font-bold text-gray-700",
    subtitle: "text-gray-600 text-sm mt-2 md:mt-0",
    form: "mt-10 flex flex-col space-y-10",
    loadingIcon: "text-center w-4 h-4 animate-spin duration-300",
    actionButton: loading =>
        `text-white text-center text-sm px-5 py-3 text-center w-full bg-ccyan rounded hover:bg-ccyan-dark duration-300 flex items-center justify-center space-x-5 ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
        }`,
    formError: error =>
        `mt-5 text-xs text-red-600 text-center duration-300 ${error ? "opacity-100" : "opacity-0"}`,
};

export default Component;
